<script setup>
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import LocalStorage from '@/controller/LocalStorage.js';
import LocaleSwitcher from "@/components/common/locale/LocaleSwitcher.vue";
import RecoverAccountInnerForm from '@/components/recoverPassword/RecoverAccountInnerForm.vue';
const branding = ref(LocalStorage.getBranding());
const { t } = useI18n();
const i18n = useI18n();
const imgYautyPath = "../public/assets/login/login_logo.svg";
const imgLogginPath = '../public/assets/login/cinturo_w.jpg';

let locale = ref(i18n.locale)
function onChangeInput(newVal) {
  locale.value = newVal;
  localStorage.setItem('yautyLanguage', newVal);
}
onMounted(() => {
  if (localStorage.getItem('yautyLanguage')) {
    locale.value = localStorage.getItem('yautyLanguage') ?? 'es';
  }
});
</script>
<template>
  <section class="register-container">
    <div class="register-section--left">
      <img :src="imgLogginPath" />
    </div>
    <div class="register-section--right">
      <div class="register-logo">
        <img :src="imgYautyPath"/>
      </div>
      <div class="register-message_1">
        {{ t('field.recover_password') }}
      </div>
      <div class="register-message_2">
        {{ $t('field.write_mail') }}
      </div>
      <div class="register-form">
        <RecoverAccountInnerForm />
      </div>
    </div>
  </section>
  <div class="language-selector">
    <div class="language__selector">
      <LocaleSwitcher :lang="locale" @changeInput="onChangeInput" :mode="1"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.register-container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  height: calc(100vh - 60px);
  .register-section--left {
    width: 50dvw;
    height: 100dvh;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .register-section--right {
    padding-left: 8rem;
    margin-top: 10dvh;

    
    justify-self: center; 
    max-width: 466px; 
    
    .register-message_1 {
      text-align: left;
      font: normal normal normal 51px/29px 'CircularStd-Book';
      letter-spacing: -2.55px;
      color: #000000;
      margin-top: 60px;
      line-height: 0.9;
    }
    .register-message_2 {
      text-align: left;
      font: normal normal normal 18px/29px 'CircularStd-Book';
      letter-spacing: -0.9px;
      color: #000000;
      opacity: 1;
      margin-top: 28px;
    }
    .register-form {
      margin-top: 16px;
    }
  }
  @media (min-width: 360px) {
    grid-template-columns: 0% 100%;
    .register-section--left {
      width: 0;
      img {
        width: 0;
        object-fit: cover;
      }
    } 
    .register-section--right {
      padding-left: 4rem;
    }
  }
  

  @media (min-width: 768px) {
    grid-template-columns: 50% 50%;
    .register-section--left {
      width: 50dvw;
      img {
        width: 50dvw;
        object-fit: cover;
      }
    }
    .register-section--right {
      padding-left: 8rem;
    }
  }
}
.language-selector {
  position: absolute;
  bottom: -45px;
  right: 15px;
  width: 175px;
}

@media (max-width: 600px) {
  .register-container {
    grid-template-columns: auto;
    max-width: 80%;
    margin: auto;
    .register-section--left {
      display: none;
    }
    .register-section--right {
      padding-left: inherit;
      max-width: 100%;
      margin: auto;
      text-align: center;
      .register-message_1, .register-message_2 {
        text-align: center;
      }
    }
  }
}



@media (min-width: 601px) and (max-width: 1024px) and (orientation: portrait) {
  .register-container {
    grid-template-columns: auto;
    max-width: 80%;
    margin: auto;
    .register-section--left {
      display: none;
    }
    .register-section--right {
      padding-left: inherit;
      max-width: 100%;
      margin: auto;
      text-align: center;
      .register-message_1, .register-message_2 {
        text-align: center;
      }
    }
  }
}

//Per a tablet en landscape
@media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape)  {

  .register-container {
    grid-template-columns: auto;
    max-width: 80%;
    margin: auto;
    .register-section--left {
      display: none;
    }
    .register-section--right {
      padding-left: inherit;
      max-width: 100%;
      margin: auto;
      text-align: center;
      .register-message_1, .register-message_2 {
        text-align: center;
      }
      
    }
  }
}


</style>
